import React, { Component, useContext, useEffect, useState } from 'react';
import { Route, Routes, useSearchParams } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Hlayout, Hheader, Hsidebar, Hcontent, MenuItemGroup, MenuSubItem, MenuItem, Solutions } from "hestia-component-library";
import { Authentification, LeCerbereContext } from "authentication/Context";
import { LayoutConfig, getPortalLayout } from './services/LayoutAPI';
import { GetApplicationNameFromKey, GetIconNameFromLegoUiCode } from './utils/Helpers';

const AuthenticatedApp = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { context } = useContext(LeCerbereContext);
    const [layoutConfig, setLayoutConfig] = useState<LayoutConfig>();

    useEffect(() => {
        let token = searchParams.get("privateLayout"); //R�cup�ration dans les param�tres d'URL
        if (token === null) {
            token = sessionStorage.getItem('privateLayout'); //R�cup�ration dans le cache si existe d�j�, sinon null
        }
        else {
            //Enregistrement en cache de la nouvelle valeur
            sessionStorage.setItem('privateLayout', token);

            //Suppression du token ?privateLayout dans l'url
            const updatedUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            window.history.replaceState({ path: updatedUrl }, '', updatedUrl);
        }
        if (token !== null) {
            getPortalLayout(token as string, searchParams.has("as") ? searchParams.get("as") : null)
                .then((info) => {
                    setLayoutConfig(info);
                })
        }
    }, []);


    return (<>
        <Hlayout theme={Solutions.ElciaOnline} language="fr_FR">
            <Hheader
                informationLink=""
                userAccountLink={layoutConfig?.UserMenuEntries ? layoutConfig?.UserMenuEntries[0].linkUrl : ""}
                helpMenuAvailableInformationCount={0}
                onlineHelpLink={layoutConfig?.HelpUrl ?? ""}
                contactLink={layoutConfig?.ContactUrl ?? ""}
                userLogout={layoutConfig?.LogOffUrl ?? ""}
                user={context.FirstNameAs !== undefined || context.LastNameAs !== undefined ?
                    "Administrateur ELCIA en tant que " + context.FirstNameAs + " " + context.LastNameAs :
                    context.FirstName + " " + context.LastName}
                title="Administration des commandes en cours" />
            <Hsidebar
                elciaLink={layoutConfig?.RedirectLogoUrl ?? ""}
                techHelpLink={layoutConfig?.TechnicalAssistanceUrl ?? ""}
                legalNoticesLink={layoutConfig?.LegalNoticeUrl ?? ""}
                eulaLink={layoutConfig?.EulaUrl ?? ""}
                applications={layoutConfig?.UserApplications?.map(x => {
                    return {
                        name: GetApplicationNameFromKey(x.key ?? ""),
                        icon: x.sourceLogo ?? "",
                        link: x.linkUrl ?? ""
                    }
                })}




            >
                {layoutConfig?.Entries?.map((entry, index) => {
                    if (entry.children.length > 1) {
                        return (
                            <MenuItemGroup unfolded={entry.children.filter(x => x.isCurrent).length > 0} key={index} icon={GetIconNameFromLegoUiCode(entry.uiCode)} title={entry.label}>
                                {entry.children.map((child, childIndex) => {
                                    return (
                                        <MenuSubItem value={child.badgeValue ? +child.badgeValue : undefined} active={child.isCurrent} key={"c-" + childIndex} title={child.label} link={child.linkUrl} />
                                    )
                                })}
                            </MenuItemGroup>
                        )
                    }
                    else {
                        return (
                            <MenuItem active={entry.isCurrent} key={index} icon={GetIconNameFromLegoUiCode(entry.uiCode)} title={entry.label} link={entry.linkUrl + (searchParams.has("as") && !entry.linkUrl.includes("?as=") ? "&as=" + searchParams.get("as") : "")} />
                        )
                    }
                })}
            </Hsidebar>

            <Hcontent title="">
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;
                        return (
                            <Route key={index} {...rest} element={element} />
                        );
                    })}
                </Routes>
            </Hcontent>

        </Hlayout>
    </>)
}

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Authentification>
                <AuthenticatedApp />
            </Authentification>
        );
    }
}
