import { envConfiguration } from 'hestia-component-library';
import { client, v2 } from '@datadog/datadog-api-client';

/**
 * Envoi un KPI nommé (simple) sur DataDog.
 * @param name Nom du KPI.
 */
const sendKpi = async (name: string) => {

    // https://datadoghq.dev/datadog-api-client-typescript/classes/v2.MetricsApi.html#submitMetrics
    // https://github.com/DataDog/datadog-api-client-typescript
    const configurationOpts = {
        authMethods: {
            apiKeyAuth: envConfiguration.configuration.DDApiKey,
            appKeyAuth: envConfiguration.configuration.DDApplicationId
        }
    };
    const configuration = client.createConfiguration(configurationOpts);
    const instance = new v2.MetricsApi(configuration);
    await instance.submitMetrics({
        body: {
            series: [
                {
                    metric: 'SupplierInterface_' + name,
                    type: 1, // count
                    points: [
                        {
                            timestamp: Math.floor(Date.now() / 1000),
                            value: 1.0
                        }
                    ],
                    tags: ['environment:' + envConfiguration.configuration.DDEnv]
                }
            ]
        }
    });


};


export { sendKpi };
