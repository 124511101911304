import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { LoadEnv, envConfiguration } from 'hestia-component-library';
import { datadogRum } from '@datadog/browser-rum';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') ?? undefined;
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);


LoadEnv().then(() => {
    if (envConfiguration.configuration.DDApplicationId !== undefined && envConfiguration.configuration.DDApplicationId !== null && envConfiguration.configuration.DDApplicationId !== "") {
        datadogRum.init({
            applicationId: envConfiguration.configuration.DDApplicationId,
            clientToken: envConfiguration.configuration.DDClientToken,
            site: envConfiguration.configuration.DDSite,
            service: envConfiguration.configuration.DDService,
            env: envConfiguration.configuration.DDEnv,
            // Specify a version number to identify the deployed version of your application in Datadog 
            // version: '1.0.0', 
            sessionSampleRate: Number(envConfiguration.configuration.DDSessionSampleRate),
            sessionReplaySampleRate: Number(envConfiguration.configuration.DDSessionReplaySampleRate),
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            silentMultipleInit: true,
            trackFrustrations: true
        });

        datadogRum.startSessionReplayRecording();
    }


    root.render(<BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>);
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
